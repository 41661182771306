export function isFirefoxDesktop(): boolean {
    return /Firefox\/\d+/.test(navigator.userAgent) && !/Mobi|Android|iPhone|iPad/.test(navigator.userAgent);
}

export function isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function isAndroid(): boolean {
    return /Android/.test(navigator.userAgent);
}

export async function isAndroidVersionAtLeast12(): Promise<boolean> {
    const ANDROID_VERSION_12 = 12;

    if (!/Android/i.test(navigator.userAgent)) {
        return false;
    }

    if (!navigator.userAgentData?.getHighEntropyValues) {
        return false;
    }

    try {
        const uad = await navigator.userAgentData.getHighEntropyValues([
            'platform',
            'platformVersion',
        ]);

        if (uad.platform !== 'Android' || !uad.platformVersion) {
            return false;
        }

        const version = parseFloat(uad.platformVersion);
        if (Number.isNaN(version)) {
            return false;
        }

        return version >= ANDROID_VERSION_12;
    } catch (error) {
        return false;
    }
}
